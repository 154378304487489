.sweet-overlay {
    background-color: #000;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 10000
}
.sweet-alert {
    background-color: #fff;
    width: 478px;
    padding: 30px;
    border-radius: 2px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -256px;
    margin-top: -200px;
    overflow: hidden;
    display: none;
    z-index: 99999
}
@media all and (max-width: 540px) {
    .sweet-alert {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        left: 15px;
        right: 15px
    }
}
.sweet-alert h2 {
    color: #000;
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    text-transform: none;
    position: relative;
    margin: 0 0 5px;
    padding: 0;
    line-height: 40px;
    display: block
}
.sweet-alert p {
    color: #5e5e5e;
    font-size: 13px;
    font-weight: 300;
    position: relative;
    text-align: inherit;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal
}
.sweet-alert button {
    background-color: #AEDEF4;
    color: #fff;
    border: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 300;
    border-radius: 2px;
    padding: 6px 10px;
    margin: 26px 5px 0;
    cursor: pointer;
    text-transform: uppercase;
}
.sweet-alert button:focus {
    outline: 0;
    box-shadow: 0 0 2px rgba(128, 179, 235, .5), inset 0 0 0 1px rgba(0, 0, 0, .05)
}
.sweet-alert button:hover {
    background-color: #a1d9f2
}
.sweet-alert button:active {
    background-color: #81ccee
}
.sweet-alert button.cancel {
    background-color: #D0D0D0
}
.sweet-alert button.cancel:hover {
    background-color: #c8c8c8
}
.sweet-alert button.cancel:active {
    background-color: #b6b6b6
}
.sweet-alert button.cancel:focus {
    box-shadow: rgba(197, 205, 211, .8) 0 0 2px, rgba(0, 0, 0, .0470588) 0 0 0 1px inset!important
}
.sweet-alert button::-moz-focus-inner {
    border: 0
}
.sweet-alert[data-has-cancel-button=false] button {
    box-shadow: none!important
}
.sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
    padding-bottom: 40px
}
.sweet-alert .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 50%;
    margin: 0 auto 20px;
    padding: 0;
    position: relative;
    box-sizing: content-box
}
.sweet-alert .sa-icon.sa-error {
    border-color: #F27474
}
.sweet-alert .sa-icon.sa-error .sa-x-mark {
    position: relative;
    display: block
}
.sweet-alert .sa-icon.sa-error .sa-line {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: #F27474;
    display: block;
    top: 37px;
    border-radius: 2px
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 17px
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 16px
}
.sweet-alert .sa-icon.sa-warning {
    border-color: #F8BB86
}
.sweet-alert .sa-icon.sa-warning .sa-body {
    position: absolute;
    width: 5px;
    height: 47px;
    left: 50%;
    top: 10px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #F8BB86
}
.sweet-alert .sa-icon.sa-warning .sa-dot {
    position: absolute;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 10px;
    background-color: #F8BB86
}
.sweet-alert .sa-icon.sa-info {
    border-color: #C9DAE1
}
.sweet-alert .sa-icon.sa-info::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 29px;
    left: 50%;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #C9DAE1
}
.sweet-alert .sa-icon.sa-info::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
    background-color: #C9DAE1
}
.sweet-alert .sa-icon.sa-success {
    border-color: #A5DC86
}
.sweet-alert .sa-icon.sa-success::after,
.sweet-alert .sa-icon.sa-success::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 120px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}
.sweet-alert .sa-icon.sa-success::before {
    -webkit-border-radius: 120px 0 0 120px;
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px
}
.sweet-alert .sa-icon.sa-success::after {
    -webkit-border-radius: 0 120px 120px 0;
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 60px;
    transform-origin: 0 60px
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(165, 220, 134, .2);
    -webkit-border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2
}
.sweet-alert .sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: #fff;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
.sweet-alert .sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: #A5DC86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
.sweet-alert .sa-icon.sa-custom {
    background-size: contain;
    border-radius: 0;
    border: none;
    background-position: center center;
    background-repeat: no-repeat
}
@-webkit-keyframes showSweetAlert {
    0% {
        transform: scale(.7);
        -webkit-transform: scale(.7)
    }
    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05)
    }
    80% {
        transform: scale(.95);
        -webkit-tranform: scale(.95)
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}
@keyframes showSweetAlert {
    0% {
        transform: scale(.7);
        -webkit-transform: scale(.7)
    }
    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05)
    }
    80% {
        transform: scale(.95);
        -webkit-tranform: scale(.95)
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}
@-webkit-keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
    100% {
        transform: scale(.5);
        -webkit-transform: scale(.5)
    }
}
@keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
    100% {
        transform: scale(.5);
        -webkit-transform: scale(.5)
    }
}
.showSweetAlert {
    -webkit-animation: showSweetAlert .3s;
    animation: showSweetAlert .3s
}
.showSweetAlert[data-animation=none] {
    -webkit-animation: none;
    animation: none
}
.hideSweetAlert {
    -webkit-animation: hideSweetAlert .2s;
    animation: hideSweetAlert .2s
}
.hideSweetAlert[data-animation=none] {
    -webkit-animation: none;
    animation: none
}
@-webkit-keyframes animateSuccessTip {
    0%, 54% {
        width: 0;
        left: 1px;
        top: 19px
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px
    }
}
@keyframes animateSuccessTip {
    0%, 54% {
        width: 0;
        left: 1px;
        top: 19px
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px
    }
}
@-webkit-keyframes animateSuccessLong {
    0%, 65% {
        width: 0;
        right: 46px;
        top: 54px
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px
    }
}
@keyframes animateSuccessLong {
    0%, 65% {
        width: 0;
        right: 46px;
        top: 54px
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px
    }
}
@-webkit-keyframes rotatePlaceholder {
    0%, 5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)
    }
    100%,
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg)
    }
}
@keyframes rotatePlaceholder {
    0%, 5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)
    }
    100%,
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg)
    }
}
.animateSuccessTip {
    -webkit-animation: animateSuccessTip .75s;
    animation: animateSuccessTip .75s
}
.animateSuccessLong {
    -webkit-animation: animateSuccessLong .75s;
    animation: animateSuccessLong .75s
}
.sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in
}
@-webkit-keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg);
        -webkit-transform: rotateX(100deg);
        opacity: 0
    }
    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1
    }
}
@keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg);
        -webkit-transform: rotateX(100deg);
        opacity: 0
    }
    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1
    }
}
.animateErrorIcon {
    -webkit-animation: animateErrorIcon .5s;
    animation: animateErrorIcon .5s
}
@-webkit-keyframes animateXMark {
    0%, 50% {
        transform: scale(.4);
        -webkit-transform: scale(.4);
        margin-top: 26px;
        opacity: 0
    }
    80% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        margin-top: -6px
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        margin-top: 0;
        opacity: 1
    }
}
@keyframes animateXMark {
    0%, 50% {
        transform: scale(.4);
        -webkit-transform: scale(.4);
        margin-top: 26px;
        opacity: 0
    }
    80% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        margin-top: -6px
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        margin-top: 0;
        opacity: 1
    }
}
.animateXMark {
    -webkit-animation: animateXMark .5s;
    animation: animateXMark .5s
}
@-webkit-keyframes pulseWarning {
    0% {
        border-color: #F8D486
    }
    100% {
        border-color: #F8BB86
    }
}
@keyframes pulseWarning {
    0% {
        border-color: #F8D486
    }
    100% {
        border-color: #F8BB86
    }
}
.pulseWarning {
    -webkit-animation: pulseWarning .75s infinite alternate;
    animation: pulseWarning .75s infinite alternate
}
@-webkit-keyframes pulseWarningIns {
    0% {
        background-color: #F8D486
    }
    100% {
        background-color: #F8BB86
    }
}
@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486
    }
    100% {
        background-color: #F8BB86
    }
}
.pulseWarningIns {
    -webkit-animation: pulseWarningIns .75s infinite alternate;
    animation: pulseWarningIns .75s infinite alternate
}